'use client';

import SkeletonCards from '@/components/SkeletonCards';
import { useRelatedEntries } from '@/lib/getters/useRelatedEntries';
import { isEntry } from '@/lib/parsers/entries';
import { useAppLink, useView } from '@/lib/store/hooks';
import { maybeGet, toNumber } from '@liquorice/allsorts-craftcms-nextjs';
import { CategoryRelationCriteriaInput, RelatedEntriesQueryVariables } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import { BlockContainer } from '../Blocks';
import CardGrid from '../CardGrid';
import CtaButton from '../CtaButton';
import EntryCard from '../EntryCard';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';

export default function RelatedEntries() {
  const view = useView();
  const articleIndexLink = useAppLink('articleIndex');
  const t = useTranslations('article');

  const variables: RelatedEntriesQueryVariables = {
    exclude: view?.id,
    orderBy: 'postDate DESC',
    section: ['article'],
  };

  if (isEntry(view, 'profile')) {
    variables.entryId = view?.id;
  }

  if (isEntry(view, 'article')) {
    const contentTypeCategorySingle = view['contentTypeCategorySingle']?.id;
    const sectorMultiple = view['sectorMultiple'].map((s) => s.id);
    const serviceMultiple = view['serviceMultiple'].map((s) => s.id);
    const productMultiple = view['productMultiple'].map((s) => s.id);
    const categoryId = {
      id: contentTypeCategorySingle,
    } as unknown as CategoryRelationCriteriaInput;

    variables.entryId = [...sectorMultiple, ...serviceMultiple, ...productMultiple];

    // Only if no sector or service is tagged in the current article, display most recent by content type category
    if (sectorMultiple.length === 0 && serviceMultiple.length === 0) {
      variables.categoryId = [categoryId];
    }
  }

  if (isEntry(view, 'sector') || isEntry(view, 'service') || isEntry(view, 'product')) {
    const parentId = toNumber(maybeGet(view, 'parent')?.id) || null;
    variables.parentId = parentId;
    variables.entryId = view?.id;
  }

  const { result, loading } = useRelatedEntries(variables) ?? {};

  return (
    (!!result?.length || !!loading) && (
      <BlockContainer {...{ marginY: true, disableTransition: true }}>
        <Flex
          justifyContent="between"
          cx={{
            mB: '2xl',
            direction: { xs: 'column', lg: 'row' },
            alignItems: { xs: 'start', lg: 'end' },
            rowGap: { xs: 'sm', lg: 'none' },
          }}>
          <Txt variant="h2" as="span">
            {t('related')}
          </Txt>
          <CtaButton href={articleIndexLink?.uri ?? '/news'}>{t('viewAll')}</CtaButton>
        </Flex>
        {loading ? (
          <SkeletonCards
            amount={4}
            md={6}
            lg={3}
            SkeletonItems={{ items: [{ variant: 'rounded', height: 300 }] }}
          />
        ) : (
          result && (
            <CardGrid
              items={result}
              Component={EntryCard}
              ItemProps={{
                md: 6,
                lg: 3,
              }}
            />
          )
        )}
      </BlockContainer>
    )
  );
}
