import { Entry } from '@/lib/parsers/entries';
import { useElementSize } from '@/lib/utils/useElementSize';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useEffect, useRef } from 'react';
import Icon, { IconProps } from '../Icon';
import Box, { BoxProps } from '../ui/Box';
import Btn from '../ui/Btn';
import Txt from '../ui/Txt';
import * as styles from './AreaList.css';

type List = BoxProps<
  'li',
  {
    item?: Partial<Entry>;
    IconProps?: Omit<IconProps, 'name'>;
  }
>;

export default function ListItem({ item, IconProps, ...props }: List) {
  const listRef = useRef<HTMLLIElement>(null);
  const spanRef = useRef<HTMLLIElement>(null);
  const list = useElementSize(listRef);
  const span = useElementSize(spanRef);

  const percentageWidth = Math.round((span.width / list.width) * 100);
  const maxWidth = percentageWidth > 80;

  const content = item?.pageTitle ?? item?.title;

  useEffect(() => {
    if (maxWidth && spanRef.current) {
      const span = spanRef.current;
      if (span) {
        const words = content?.trim().split(' ');

        if (words && span && words?.length > 1) {
          const lastWord = words.pop();
          span.innerHTML = `${words.join(' ')}<br>${lastWord}`;
        } else {
          span.innerHTML = content ?? ''; // Handle case where there's only one word
        }
      }
    }
  }, [content, maxWidth]);

  return (
    item?.uri && (
      <Box
        ref={listRef}
        key={item.id}
        as="li"
        cx={{ mR: '3xl' }}
        {...mergePropsClassName(props, styles.listItem({ maxWidth }))}>
        <Btn className={styles.link} variant="text" wrap href={item.uri}>
          <Box>
            <Txt ref={spanRef} as="span">
              {content}
            </Txt>
            <Txt as="span" cx={{ mL: '2xs' }}>
              <Icon
                name="arrowRight"
                cx={{ mB: '4xs' }}
                {...mergePropsClassName(IconProps, styles.icon)}
              />
            </Txt>
          </Box>
        </Btn>
      </Box>
    )
  );
}
