'use client';

import { Entry } from '@/lib/parsers/entries';
import { StandardLayoutEntries } from '@/lib/parsers/entries/customEntryTypes';
import { useView } from '@/lib/store/hooks';
import { useTranslations } from 'next-intl';
import ListItem from '../AreaList/AreaList.ListItem';
import { BlockContainer } from '../Blocks';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import Txt, { TxtProps } from '../ui/Txt';

type RelevantAreasProps = {
  entries?: Partial<Entry>[];
};

type AreaTitle = TxtProps<
  'h5',
  {
    title?: string;
  }
>;

const AreaTitle = ({ title }: AreaTitle) => {
  return (
    <Txt as="h5" variant="h5" cx={{ mB: { xs: 'sm', lg: 'lg' } }}>
      {title}
    </Txt>
  );
};

const Area = ({ entries }: Partial<RelevantAreasProps>) => {
  return (
    <Flex rowGap="3xs" alignItems="start" as="ul" style={{ listStyle: 'none' }}>
      {entries?.map(
        (item) =>
          item && (
            <ListItem
              key={item.id}
              item={item}
              cx={{ mR: 'none' }}
              IconProps={{ cx: { color: 'accent' } }}
            />
          )
      )}
    </Flex>
  );
};

export default function RelevantAreas() {
  const page = useView() as StandardLayoutEntries;
  const t = useTranslations('area');

  const services = page.serviceMultiple || [];
  const sectors = page.sectorMultiple || [];
  const products = page.productMultiple || [];

  return (
    (!!services.length || !!sectors.length) && (
      <BlockContainer paddingY colorSet="secondary" paper {...{ disableTransition: true }}>
        <Grid cx={{ rowGap: '3xl', columnGap: { lg: '3xl' } }}>
          {!!sectors.length && (
            <Grid.Col md={4} lg={3}>
              <AreaTitle title={t('relatedSectors')} />
              <Area entries={sectors} />
            </Grid.Col>
          )}
          {!!services.length && (
            <Grid.Col md={4} lg={3}>
              <AreaTitle title={t('relatedServices')} />
              <Area entries={services} />
            </Grid.Col>
          )}
          {!!products.length && (
            <Grid.Col md={4} lg={3}>
              <AreaTitle title={t('relatedProducts')} />
              <Area entries={products} />
            </Grid.Col>
          )}
        </Grid>
      </BlockContainer>
    )
  );
}
