import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/dist/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FMigratedContent%2FMigratedContent.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xc2VoYTFoMiB7CiAgbWFyZ2luOiAwIDAgMWVtOwp9Ci5fMXNlaGExaDQgewogIG1hcmdpbjogMCAwIDFlbTsKfQouXzFzZWhhMWg4IHsKICBwYWRkaW5nLWxlZnQ6IDEuOGNoOwp9Ci5fMXNlaGExaGEgewogIHBhZGRpbmctbGVmdDogMS44Y2g7Cn0KLl8xc2VoYTFoYyB7CiAgbWFyZ2luLWJvdHRvbTogMC42ZW07Cn0KLl8xc2VoYTFoaSB7CiAgZGlzcGxheTogaW5saW5lLWJsb2NrOwogIHZlcnRpY2FsLWFsaWduOiBzdXBlcjsKICBtYXJnaW4tbGVmdDogMC4yZW07CiAgbWFyZ2luLXJpZ2h0OiAwLjRlbTsKfQouXzFzZWhhMWg2IC5fMXNlaGExaGo6bGFzdC1jaGlsZCB7CiAgbWFyZ2luLWJvdHRvbTogMDA7Cn0KLl8xc2VoYTFoNiAuXzFzZWhhMWhqOmZpcnN0LWNoaWxkOmxhc3QtY2hpbGQgewogIG1hcmdpbjogMC42ZW07Cn0KLl8xc2VoYTFoayB7CiAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7Cn0KLl8xc2VoYTFoaiAuXzFzZWhhMWhrIHsKICB0ZXh0LWRlY29yYXRpb246IG5vbmU7CiAgZm9udC1mYW1pbHk6IGluaGVyaXQ7CiAgY29sb3I6IGluaGVyaXQ7CiAgZm9udC13ZWlnaHQ6IGluaGVyaXQ7Cn0KLl8xc2VoYTFoMCB0YWJsZSB7CiAgbWFyZ2luLXRvcDogMmVtOwogIG1hcmdpbi1ib3R0b206IDJlbTsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FNavBar%2FNavbar.css.ts.vanilla.css%22%2C%22source%22%3A%22Ym9keSB7CiAgLS1fMXd4bm41aTM6IDg0cHg7Cn0KLl8xd3hubjVpNiB7CiAgei1pbmRleDogdmFyKC0tb3J4cGs2MSk7CiAgcG9zaXRpb246IGZpeGVkOwogIHRvcDogMDsKICBsZWZ0OiAwOwogIHJpZ2h0OiAwOwogIGhlaWdodDogdmFyKC0tXzF3eG5uNWkzKTsKfQouXzF3eG5uNWk3IHsKICB0cmFuc2l0aW9uOiB2YXIoLS1vcnhwazYxMCk7Cn0KLl8xd3hubjVpYiB7CiAgaGVpZ2h0OiB2YXIoLS1fMXd4bm41aTMpOwp9Ci5fMXd4bm41aWUgewogIHdpZHRoOiAxMDB2dzsKICBwYWRkaW5nLXRvcDogdmFyKC0tXzF3eG5uNWkzKTsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FPageHeader%2FPageHeader.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xN2YxN3VmMSB7CiAgcGFkZGluZy10b3A6IHZhcigtLV8xN2YxN3VmMCk7Cn0KLl8xN2YxN3VmMiB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogMDsKICBsZWZ0OiAwOwogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogMTAwJTsKICB6LWluZGV4OiAtMTsKfQouXzE3ZjE3dWYzIHsKICBwb3NpdGlvbjogcmVsYXRpdmU7Cn0KLl8xN2YxN3VmMzo6YmVmb3JlIHsKICBjb250ZW50OiAiIjsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgYm90dG9tOiAwOwogIGxlZnQ6IDA7CiAgd2lkdGg6IDEwMCU7CiAgaGVpZ2h0OiA0MCU7CiAgYmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KDBkZWcsIHJnYmEoMjU1LCAyNTUsIDI1NSwgMSkgMCUsIHJnYmEoMCwgMCwgMCwwKSAxMDAlKTsKICB6LWluZGV4OiA5OTk7Cn0KLl8xN2YxN3VmNCB7CiAgdGV4dC1kZWNvcmF0aW9uOiBub25lOwogIGNvbG9yOiBpbmhlcml0Owp9Ci5fMTdmMTd1ZjUgewogIGhlaWdodDogdmFyKC0tXzF3eG5uNWkzKTsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FShim%2FShim.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xb3EzbnU3MCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMW9xM251NzEgewogIGNsaXAtcGF0aDogY2lyY2xlKCk7Cn0KLl8xb3EzbnU3MiB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogMDsKICBsZWZ0OiAwOwogIGJvdHRvbTogMDsKICByaWdodDogMDsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Blocks/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Breadcrumbs/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Icon/Icon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/LanguageSelector/LanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/MigratedContent/MigratedContent.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.ArticleIndex.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.Error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.Home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.Profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/PageHeader/PageMeta.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/PageHeader/PageSummary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/PageHeader/PageTitle.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/RelatedEntries/RelatedEntries.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/RelevantAreas/RelevantAreas.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Shim/Shim.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/lib/store/context/AppContextProviderClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/lib/store/context/createAppContext.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/theme/utils/useBreakpoints.ts");
